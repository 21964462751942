.home-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: justify;
}

.home-container h1 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 20px;
}

.home-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.home-container p,
.home-container ul {
  font-size: 1.1rem;
  line-height: 1.6;
}

.home-container ul {
  margin-bottom: 15px;
  padding-left: 20px;
}

.home-container ul li {
  margin-bottom: 8px;
}

.introduction {
  margin-bottom: 20px;
}

.heatmap {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.heatmap img {
  max-width: 60%;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.carousel-container {
  margin-top: 20px;
  display: flex;
}

.carousel img {
  width: 100%; /* Adjust based on your desired size */
  height: 400px; /* Set a fixed height */
  object-fit: cover;
  object-position: center;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.image-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.carousel .thumb img {
  max-height: 50px; 
  object-fit: cover;
  object-position: center;
}
