.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    background-color: #425265;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: opacity 0.4s ease;
    opacity: 0; /* Initially hidden */
    pointer-events: none; /* Initially not clickable */
}

.scroll-to-top.visible {
    opacity: 1;
    pointer-events: auto;
}

.scroll-to-top:hover {
    opacity: 0.8;
}