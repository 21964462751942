/* Basic styling for ContactPage */
.contact-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }

  .contact-page h1 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .contact-info {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  h2 {
    color: #333;
  }
  
  h3 {
    color: #666;
    margin-bottom: 10px;
  }
  
  p {
    margin-bottom: 5px;
  }
  
  .note {
    font-style: italic;
    color: #888;
  }
  