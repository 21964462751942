/* Basic styling for AboutPage */
.about-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }

  .about-page h1 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .mission-section,
  .vision-section,
  .values-section {
    margin-bottom: 40px;
  }
  
  h2 {
    color: #333;
  }
  
  p {
    line-height: 1.6;
  }
  