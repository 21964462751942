.solar-geyser-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: justify;
}

.solar-geyser-container h1 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 20px;
}

.solar-geyser-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.solar-geyser-container p,
.solar-geyser-container ol,
.solar-geyser-container ul {
  font-size: 1.1rem;
  line-height: 1.6;
}

.solar-geyser-container ol {
  margin-bottom: 15px;
  padding-left: 20px;
}

.solar-geyser-container ul {
  margin-bottom: 15px;
  padding-left: 20px;
}

.solar-geyser-container ul li,
.solar-geyser-container ol li {
  margin-bottom: 8px;
}

.analysis-diagram {
  margin-top: 20px;
  text-align: center;
}

.analysis-diagram img {
  width: 80%;
  height: auto;
}