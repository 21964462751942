.header {
  background-color: #228B22;
  color: white;
  text-align: center; /* Center align header */
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo a {
  color: white;
  font-size: 15px;
  text-decoration: none;
  font-weight: bold;
}

.logo-img {
  width: 250px; /* Adjust logo width as needed */
  height: auto; /* Maintain aspect ratio */
}

.nav {
  background-color: transparent;
  padding: 20px;
}

.nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center; /* Center items vertically */
  margin: 0;
  padding: 0;
}

.nav ul li {
  position: relative;
  padding: 0 10px; /* Adjust padding for spacing */
}

.nav ul li:not(:last-child) {
  border-right: 1px solid #fff; /* Vertical divider */
}

.nav ul li a,
.dropdown-toggle {
  color: white;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s, border-color 0.3s; /* Added transition for border-color */
  border-bottom: 2px solid transparent; /* Initially transparent */
}

.nav ul li a:hover,
.dropdown-toggle:hover {
  color: #ddd;
  border-bottom-color: white; /* Change border color on hover */
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%; /* Position below the parent */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%);
  width: 200px;
  height: 80px;
  border-radius: 10px;
  background-color: #005500;
  display: none; /* Hide by default */
}

.dropdown-menu.active {
  display: flex; /* Show when active */
  flex-direction: column;
}

.dropdown-menu li {
  width: 100%;
}

.dropdown-menu li a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  padding: 10px; /* Adjust padding for spacing */
  transition: color 0.3s;
}

.dropdown-menu li a:hover {
  color: #ddd;
}
