.footer {
  background-color: #333;
  color: white;
  padding: 20px 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.email a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;
}

.email a:hover {
  color: #ddd;
}

.disclaimer {
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}